import React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Contact from "../components/Contact/Contact"

const ContactUs = () => (
  <Layout>
    <Seo title="Contact Us" />
    <Contact />
  </Layout>
)

export default ContactUs
