import React from "react"
import Container from "../Container/Container"
import * as styles from "./styles.module.scss"

//Icons
import Phone from "../../images/Icons/Contact/Phone.svg"
import Email from "../../images/Icons/Contact/Email.svg"
import Location from "../../images/Icons/Contact/Location.svg"

const Contact = () => {
  return (
    <Container>
      <section className={styles.contact}>
        <h1 className={styles.contactHeading}>Get in touch!</h1>

        <div className={styles.contactDetails}>
          <a
            href="https://goo.gl/maps/fUavu2oLfuYbfR1N9"
            className={styles.contactLink}
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.contactDetail}>
              <img
                src={Location}
                className={styles.contactDetailIcon}
                alt="Location"
              ></img>
              <p className={styles.contactDetailText}>
                UK Tower, Near Uppala Checkpost, Kasaragod, Kerala - 671323
              </p>
            </div>
          </a>

          <a
            href="tel:+91 8848897756"
            className={styles.contactLink}
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.contactDetail}>
              <img
                src={Phone}
                className={styles.contactDetailIcon}
                alt="Phone"
              ></img>
              <p className={styles.contactDetailText}>+91 88488 97756</p>
            </div>
          </a>

          <a
            href="mailto:uktraders@gmail.com"
            className={styles.contactLink}
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.contactDetail}>
              <img
                src={Email}
                className={styles.contactDetailIcon}
                alt="Email"
              ></img>
              <p className={styles.contactDetailText}>
                uktradershosangadi@gmail.com
              </p>
            </div>
          </a>
        </div>
      </section>
    </Container>
  )
}

export default Contact
